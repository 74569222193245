.app-heading {
  text-transform: uppercase;
  color: #FFFFFF;
}


.sheet-wrapper table {
  width: 100%;
  background-color: #000;
  border-spacing: 1px;
  table-layout: fixed;
}
.sheet-wrapper table tr>td:first-child{
  width: 140px; 
  padding: 4px;
}
.sheet-wrapper table td {
  padding: 0px 4px;
  width: 100px;
  /*font-weight: 500;*/
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  background-color: inherit;
  white-space: nowrap;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.sheet-wrapper table td.grow {
  width: 100%;
}

.sheet-wrapper table td.blank {
  /*width: 100px;
  display: inline-table;*/
}

.sheet-wrapper table td.pointer {
  cursor: pointer;
}

.sheet-wrapper table td.after6 {
  background-color: #BABABA;
}

.sheet-wrapper table td.line-through {
  text-decoration: line-through;
}

.sheet-row:nth-child(odd) {
  background-color: #EDEDED;
}

.sheet-row:nth-child(even) {
  background-color: #D3D3D3;
}

.sheet-row.weekend {
  background-color: #FFE699;
}

.preloader-wrapper, .login-wrapper {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sheet-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile.sheet-header {
  display: none;
}

@media (pointer: coarse), (hover: none) {
  .sheet-header {
    display: none;
  }
  .mobile.sheet-header {
    display: flex;
  }
  .sheet-wrapper {
    overflow: scroll;
    margin-left: 100px;
  }  
  .sheet-wrapper table tr>td:first-child{
    position: absolute;
    left: 0;
    padding: 1px 4px;
    border: 1px solid;
    margin-top:-1px;
  }
}